import React, {useState, useEffect, useContext, useCallback} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import AsideHeader from '^common/asideHeader';
import {
    Container,
    Row,
    Accordion,
    Spinner,
} from 'react-bootstrap';
import CustomAccordion from '^common/customAccordion';
import {
    getDescendants,
    getDescendantNames,
    renderDescendants,
    toggleAccordion,
} from '^pages/workTicket/utilities/kittingAssemblyHelper';
import {toast} from 'react-toastify';
import AddToCard from '^pages/workTicket/commonAddtoAssemblyKitting/addToCard';
import AddToAssemblyKittingFilter from '^pages/workTicket/commonAddtoAssemblyKitting/AddToAssemblyKittingFilter';
import EmptyCard from '^pages/workTicket/commonAssemblyKitting/emptyCard';

const WorkTicketAddToAssembly = ({
    unit,
    unitsArray,
    updateAllUnits,
    updateUnitsState,
}) => {
    const api = useContext(ApiContext);

    const [thisUnit, setThisUnit] = useState(_.cloneDeep(unit));
    const [component, setComponent] = useState(null);
    const [potentialComponents, setPotentialComponents] = useState({});

    const [loading, setLoading] = useState(true);
    const [allUnits, setAllUnits] = useState(unitsArray);
    const [descendantUnits, setDescendantUnits] = useState({
        assembled: {},
        available: {},
    });

    const [barcodeUnits, setBarcodeUnits] = useState(null);
    const [unitDescendantNames, setUnitDescendantNames] = useState(null);

    const [descendantTrees, setDescendantTrees] = useState({
        assembled: {},
        available: {},
    });

    const [partConfigFilter, setPartConfigFilter] = useState(null);
    const [partConfigOptions, setPartConfigOptions] = useState(null);
    const [serializedFilter, setSerializedFilter] = useState(null);

    const [componentsKey, setComponentsKey] = useState('0');

    const fetchParentUnit = useCallback(async (id) => {
        const response = await api.get(`/units/${id}`);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return null;
        }

        return response?.data;
    }, [api]);

    useEffect(() => {
        if (_.isNil(partConfigFilter) && componentsKey !== '0') {
            toggleAccordion(setComponentsKey, '0');
        }
    }, [partConfigFilter, componentsKey]);

    useEffect(() => {
        if (!_.isNil(thisUnit?.assembled_into_unit_id)) {
            fetchParentUnit(thisUnit?.assembled_into_unit_id)
                .then((result) => setComponent(result))
                .catch((e) => toast.error(e));
        }
    }, [thisUnit?.assembled_into_unit_id, fetchParentUnit]);

    useEffect(() => {
        const fetchAssemblyUnits = async (
            stage,
            hasSubcomponents,
            id,
            units,
        ) => {
            setLoading(true);

            let mainComponents;
            if (hasSubcomponents) {
                const response = await api.get(`/units/${id}/assembly`);

                if (isResponseError(response)) {
                    toast.error('Failed to fetch Unit assembly');
                    setLoading(false);
                    return;
                }

                setDescendantUnits((prevState) => ({
                    ...prevState,
                    assembled: response?.data,
                }));

                mainComponents = _.chain(units)
                    .differenceBy(response?.data, 'unit_id')
                    .filter((unit) => (
                        unit.assembled_into_unit_id === null
                        && unit.serial_number !== null
                        && unit.unit_id !== id))
                    .value();
            } else {
                setDescendantUnits((prevState) => ({
                    ...prevState,
                    assembled: null,
                }));

                mainComponents = _.filter(units, (unit) => (
                    unit.assembled_into_unit_id === null
                    && unit.serial_number !== null
                    && unit.unit_id !== id
                ));
            }

            if (stage !== 'Rolled-Back') {
                mainComponents = _.filter(mainComponents,
                    (unit) => unit.stage !== 'Rolled-Back');
            }

            const grouped = _.groupBy(mainComponents,
                (unit) =>
                    // eslint-disable-next-line max-len
                    `${unit?.sku ?? ''} (${!_.isNil(unit.serial_number) ? 'Serialized' : 'Non-Serialized'})`);
            const barcodeGrouped = _.chain(mainComponents)
                .filter((unit) => unit.barcode !== null)
                .groupBy((unit) => unit.barcode)
                .value();
            setBarcodeUnits(barcodeGrouped);
            setPotentialComponents(grouped);

            const partConfigGroups = _.chain({})
                .merge(
                    _.cloneDeep(grouped),
                    _.cloneDeep(barcodeGrouped),
                )
                .keys()
                .sortBy((item) => {
                    if (_.includes((item), '(')) {
                        const firstDigit = parseInt(item.match(/\d/)[0]);
                        return [1, firstDigit];
                    } else {
                        const firstDigit = parseInt(item.match(/\d/)[0]);
                        return [2, firstDigit];
                    }
                })
                .value();
            setPartConfigOptions(_.map(partConfigGroups, (group) => ({
                label: group,
                value: group,
            })));

            setLoading(false);
        };

        if (api && thisUnit && allUnits) {
            fetchAssemblyUnits(
                thisUnit?.stage,
                thisUnit?.has_assembly_components,
                thisUnit?.unit_id,
                allUnits,
            );
        }
    }, [api, thisUnit, allUnits]);

    useEffect(() => {
        if (!_.isNil(descendantUnits?.assembled)) {
            setDescendantTrees((prevState) => ({
                ...prevState,
                assembled: getDescendants(
                    descendantUnits.assembled,
                    component?.unit_id ?? null,
                ),
            }));
        }
    }, [descendantUnits?.assembled, component?.unit_id]);

    useEffect(() => {
        if (descendantUnits?.available || component?.unit_id) {
            const unitsWithDescendants = _.omitBy(
                descendantUnits?.available, _.isNil,
            );
            for (const [id, units] of Object.entries(unitsWithDescendants)) {
                const descendants = getDescendants(units, id);
                const unitIndex = _.findIndex(units, {'unit_id': id});
                setDescendantTrees((prevState) => ({
                    ...prevState,
                    available: {
                        ...prevState?.available,
                        [id]: {
                            children: descendants,
                            name: unitIndex >= 0
                                ? units[unitIndex]?.serial_number
                                : null,
                        },
                    },
                }));
            }
        }
    }, [descendantUnits?.available, component?.unit_id]);

    useEffect(() => {
        if (!_.isEmpty(descendantTrees?.assembled)) {
            setUnitDescendantNames(
                getDescendantNames(
                    descendantTrees?.assembled[thisUnit?.unit_id]?.children),
            );
        }
    }, [descendantTrees?.assembled, thisUnit?.unit_id]);

    const handleUpdateUnits = useCallback((unit) => {
        setAllUnits((prevState) => (
            updateUnitsState(prevState, unit, false)
        ));

        updateAllUnits(unit);
    }, [updateAllUnits, updateUnitsState]);

    const handleSubmit = useCallback(async (assemble, componentId) => {
        if (componentId) {
            const response = await api.patch('/units', {
                unit_id: [
                    thisUnit?.unit_id,
                ],
                assembled_into_unit_id: assemble
                    ? componentId
                    : null,
            });
            setLoading(true);

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
                setLoading(false);
                return 'error';
            }

            const [updatedUnit] = response.data;
            setThisUnit(updatedUnit);
            const parentUnit = await fetchParentUnit(componentId);
            handleUpdateUnits(updatedUnit);
            handleUpdateUnits(parentUnit);

            if (assemble) {
                setComponent(parentUnit);
            } else {
                setComponent(null);
            }
            setPartConfigFilter(null);
            setSerializedFilter(null);
            toggleAccordion(setComponentsKey, '0');
            setLoading(false);
            return null;
        }
    }, [
        thisUnit?.unit_id,
        api,
        setComponent,
        fetchParentUnit,
        handleUpdateUnits,
    ]);

    /* eslint-disable max-len */
    return <>
        <AsideHeader>
            {(!_.isNil(thisUnit?.assembled_into_unit_id) ? 'Remove from Assembly: ' : 'Add to Assembly: ')
                + (thisUnit?.serial_number
                    ? `${thisUnit?.serial_number}`
                    : `${thisUnit?.sku ?? ''} (1 Unit)`)}
        </AsideHeader>
        <Container className={'pb-4'}>
            <Row>
                {`Desc: ${thisUnit?.config?.part?.description}`}
            </Row>
        </Container>
        {!_.isEmpty(descendantTrees?.assembled[thisUnit?.unit_id]?.children)
            ? <Container className={'mt-n4 pb-4'}>
                <Row>
                    {'Sub Components:'}
                    {renderDescendants(descendantTrees?.assembled[thisUnit?.unit_id]?.children)}
                </Row>
            </Container>
            : null}
        {(!loading && _.isNil(thisUnit?.assembled_into_unit_id)) && <AddToAssemblyKittingFilter
            type={'assembly'}
            hide={!_.isNil(component)}
            entityList={potentialComponents}
            barcodeUnits={barcodeUnits}
            filters={{
                serializedFilter,
                partConfigFilter,
            }}
            setters={{
                setComponentsKey,
                setSerializedFilter,
                setPartConfigFilter,
            }}
            partConfigOptions={partConfigOptions}
            descendantTrees={descendantTrees}
            handleSubmit={handleSubmit}
        />}
        {loading ? <Spinner/> : (!_.isNil(thisUnit?.assembled_into_unit_id) && (
            <AddToCard
                api={api}
                setDescendantUnits={setDescendantUnits}
                assemble={false}
                unit={component}
                originalUnitId={
                    thisUnit?.serial_number
                        ? thisUnit?.unit_id
                        : thisUnit?.sku ?? ''
                }
                descendantTrees={descendantTrees}
                unitDescendantNames={unitDescendantNames}
                handleSubmit={handleSubmit}
            />
        ))}
        {(_.isNil(thisUnit?.assembled_into_unit_id) && !loading && <CustomAccordion key={'Potential Main Components'} activeKey={componentsKey}>
            <Accordion.Item eventKey={'0'}>
                <Accordion.Header onClick={() => toggleAccordion(setComponentsKey, '0')}>{'Potential Main Components'}</Accordion.Header>
                <Accordion.Body>
                    {_.isEmpty(potentialComponents) && <EmptyCard description={'No Potential Main Components'}/>}
                    {potentialComponents && Object.entries(potentialComponents).map(([sku, potentialUnits], index) => {
                        return (
                            <>
                                <CustomAccordion key={sku} defaultActiveKey={sku}>
                                    <Accordion.Item eventKey={sku}>
                                        <Accordion.Header>
                                            {sku}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {_.map(potentialUnits, (potentialUnit) => {
                                                return <AddToCard
                                                    api={api}
                                                    assemble={true}
                                                    unit={potentialUnit}
                                                    originalUnitId={null}
                                                    descendantTrees={descendantTrees}
                                                    setDescendantUnits={setDescendantUnits}
                                                    unitDescendantNames={null}
                                                    handleSubmit={handleSubmit}
                                                />;
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </CustomAccordion>
                            </>
                        );
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </CustomAccordion>)}
    </>;
    /* eslint-enable max-len */
};

export default WorkTicketAddToAssembly;

