import React, {useState, useEffect, useContext, useCallback} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import CustomAccordion from '^common/customAccordion';
import {
    Accordion,
    Container,
    Spinner,
    Row,
} from 'react-bootstrap';
import {
    countDescendants,
    getDescendants,
    renderDescendants,
    toggleAccordion,
} from '^pages/workTicket/utilities/kittingAssemblyHelper';
import AddToCard
    from '^pages/workTicket/commonAddtoAssemblyKitting/addToCard';
import {toast} from 'react-toastify';
import AddToAssemblyKittingFilter from '^pages/workTicket/commonAddtoAssemblyKitting/AddToAssemblyKittingFilter';
import EmptyCard from '^pages/workTicket/commonAssemblyKitting/emptyCard';

const WorkTicketAddToKit = ({
    unit,
    updateAllUnits,
}) => {
    const api = useContext(ApiContext);

    const workTicketGroupId = unit?.work_ticket
        ?.work_ticket_group?.work_ticket_group_id;

    const [loading, setLoading] = useState(true);

    const [descendantUnits, setDescendantUnits] = useState({
        assembled: {},
    });
    const [descendantTrees, setDescendantTrees] = useState({
        assembled: {},
    });
    const [unitKit, setUnitKit] = useState(unit?.kit);
    const [kits, setKits] = useState([]);

    const [kitFilter, setKitFilter] = useState(null);
    const [kitsKey, setKitsKey] = useState('0');

    const fetchKits = useCallback(async (id) => {
        setLoading(true);
        const response = await api.get('/kits', {
            params: {
                work_ticket_group_id: id,
            },
        });

        if (isResponseError(response)) {
            return;
        }

        return response?.data?.results;
    }, [api]);

    const fetchAssemblyUnits = useCallback(async (hasSubcomponents, id) => {
        setLoading(true);
        let units;
        if (hasSubcomponents) {
            const response = await api.get(`/units/${id}/assembly`);

            if (isResponseError(response)) {
                toast.error('Failed to fetch Unit assembly');
                setLoading(false);
                return;
            }

            units = response?.data;
        } else {
            units = null;
        }
        setLoading(false);
        return units;
    }, [api]);

    useEffect(() => {
        if (_.isNil(kitFilter) && kitsKey !== '0') {
            toggleAccordion(setKitsKey, '0');
        }
    }, [kitFilter, kitsKey]);

    useEffect(() => {
        if (workTicketGroupId && api) {
            fetchKits(workTicketGroupId)
                .then((kits) => {
                    setKits(kits);
                    setLoading(false);
                }).catch((e) => console.error(e));
            fetchAssemblyUnits(unit?.has_assembly_components, unit?.unit_id)
                .then((units) => setDescendantUnits((prevState) => ({
                    ...prevState,
                    assembled: units,
                })))
                .catch((e) => console.error(e));
        }
    }, [
        api,
        workTicketGroupId,
        unit?.has_assembly_components,
        unit?.unit_id,
        fetchKits,
        fetchAssemblyUnits,
    ]);

    useEffect(() => {
        if (!_.isNil(descendantUnits?.assembled)) {
            setDescendantTrees((prevState) => ({
                ...prevState,
                assembled: getDescendants(
                    descendantUnits.assembled,
                    unit?.unit_id ?? null,
                ),
            }));
        }
    }, [descendantUnits?.assembled, unit?.unit_id]);

    const handleUpdateKits = useCallback((kitting, kit) => {
        setKits((prevState) => {
            const existing = _.find(prevState, {kit_id: kit?.kit_id});

            let result;
            if (kitting) {
                result = _.map(prevState, (prev) =>
                    kit?.kit_id === prev.kit_id
                        ? kit
                        : prev,
                );
            } else {
                const dup = _.cloneDeep(existing);
                _.set(dup, 'unit_count', dup['unit_count'] - 1);
                result = _.map(prevState, (prev) =>
                    kit?.kit_id === prev.kit_id
                        ? dup
                        : prev,
                );
            }
            return result;
        });
    }, []);

    const handleSubmit = useCallback(async (kitting, id) => {
        if (id) {
            setLoading(true);
            const response = await api.patch('/units', {
                unit_id: [
                    unit?.unit_id,
                ],
                kit: kitting
                    ? {kit_id: id}
                    : null,
            });

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
            }

            const unitFromResponse = response?.data[0];
            updateAllUnits(unitFromResponse);

            let kit;
            if (kitting) {
                kit = response?.data[0].kit;
                handleUpdateKits(kitting, kit);
                setUnitKit(kit);
            } else {
                kit = {
                    kit_id: id,
                };
                handleUpdateKits(kitting, kit);
                setUnitKit(null);
            }
            setKitFilter(null);
            toggleAccordion(setKitsKey, '0');
            setLoading(false);
        }
    }, [api, unit?.unit_id, setUnitKit, updateAllUnits, handleUpdateKits]);

    /* eslint-disable max-len */
    return <>
        <AsideHeader>
            {(_.isNil(unitKit) ? 'Add to Kit: ' : 'Remove from Kit: ')
                + (unit?.serial_number
                    ? `${unit?.serial_number}`
                    : `${unit?.sku ?? ''} (1 Unit)`)}
        </AsideHeader>
        <Container className={'pb-4'}>
            <Row>
                {`Desc: ${unit?.config?.part?.description}`}
            </Row>
        </Container>
        {!_.isEmpty(descendantTrees?.assembled)
            ? <Container className={'mt-n4 pb-4'}>
                <Row>
                    {`${countDescendants(descendantTrees?.assembled, true)} Sub Components:`}
                    {renderDescendants(descendantTrees?.assembled)}
                </Row>
            </Container>
            : null}
        {loading ? null : <AddToAssemblyKittingFilter
            hide={!_.isNil(unitKit)}
            type={'kit'}
            entityList={kits}
            filters={{
                kitFilter,
            }}
            setters={{
                setKitFilter,
                setKitsKey,
            }}
            handleSubmit={handleSubmit}
        />}
        {loading ? <Spinner/> : !_.isNil(unitKit) ? (
            <AddToCard
                assemble={false}
                kit={unitKit}
                handleSubmit={handleSubmit}
            />) : null}
        {(_.isNil(unitKit) && !loading && <CustomAccordion key={'Available Kits'} activeKey={kitsKey}>
            <Accordion.Item eventKey={'0'}>
                <Accordion.Header onClick={() => toggleAccordion(setKitsKey, '0')}>{'Available Kits'}</Accordion.Header>
                <Accordion.Body>
                    {_.isEmpty(kits) && <EmptyCard description={'No Available Kits'}/>}
                    {kits && _.map(kits, (kit) => (
                        <AddToCard
                            assemble={true}
                            kit={kit}
                            handleSubmit={handleSubmit}
                        />
                    ))}
                </Accordion.Body>
            </Accordion.Item>
        </CustomAccordion>)}
    </>;
    /* eslint-enable max-len */
};

export default WorkTicketAddToKit;
