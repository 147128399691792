import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {limit} from '^config/pagination';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import {usePagination} from '^utilities/usePagination';
import editIcon from '^assets/images/editWhite.svg';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {DropdownToggle} from '^common/dropdownToggle';
import {TableRow} from '^common/tableRow';
import {Breadcrumb, Dropdown, Spinner} from 'react-bootstrap';
import ConfigAside from './configAside';
import ConfigEdit from './configEdit';

const ConfigList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const navigate = useNavigate();
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const {part_id: partId} = useParams();

    const [configs, setConfigs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const getConfigs = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/configs', {
            params: {
                part_id: partId,
                offset,
                limit,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setConfigs((prevState) => _.uniqBy(
            [...prevState, ...results],
            'config_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, partId]);

    useEffect(() => {
        if (api && partId) {
            setConfigs([]);
            setOffset(null);
            getConfigs(0);
        }
    }, [api, getConfigs, partId]);

    const lastRowRef = usePagination(offset, getConfigs);

    const updateConfig = useCallback((config) => {
        setConfigs((prevState) => _.map(
            prevState,
            (prev) => config?.config_id === prev.config_id
                ? config
                : prev,
        ));
    }, []);

    return <>
        <PageHeader title={'Configs'}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/')}>
                    {userFacilityLabel}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate('/parts')}>
                    {'Parts'}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {configs?.[0]?.part?.part_number ?? '…'}
                </Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={10} md={11}>
                    {'Config'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {_.map(configs, (config) =>
                <TableRow key={config.config_id}>
                    <TableCol xs={10} md={11}>
                        {`${config.label}`}
                    </TableCol>
                    <TableColActions xs={2} md={1}>
                        <TableIcon
                            src={editIcon}
                            $background={'primary'}
                            alt={'Edit'}
                            onClick={() => setAsideChildren(
                                <ConfigEdit
                                    config={config}
                                    updateConfig={updateConfig}
                                />,
                            )}
                        />
                        <Dropdown>
                            <Dropdown.Toggle as={DropdownToggle}/>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => setAsideChildren(
                                        <ConfigAside config={config}/>,
                                    )}
                                >
                                    {'View Config Details'}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </TableColActions>
                </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default ConfigList;
