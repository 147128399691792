import React, {useCallback, useContext} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {AppContext} from '^contexts/app';
import noteIcon from '^assets/images/note.svg';
import partIcon from '^assets/images/part.svg';
import workTicketGroupIcon from '^assets/images/workTicketGroup.svg';
import {DropdownToggle} from '^common/dropdownToggle';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon, TableIconText} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import {Spinner, Dropdown, Form, Row, Col} from 'react-bootstrap';
import ManageUnitsAside from './manageUnits/manageUnitsAside';
import WorkTicketAside from './aside/workTicketAside';
import WorkTicketAssignAside from './aside/workTicketAssignAside';
import WorkTicketCreateEdit from './workTicketCreateEdit';
import WorkTicketDurationIndicator from './workTicketDurationIndicator';
import WorkTicketGroupAside from './aside/workTicketGroupAside';
import WorkTicketNotesAside from './aside/workTicketNotesAside';

const TableColInnerIcon = styled(Col).attrs(() => ({
    className: 'px-1',
}))`
    max-width: 2rem;
`;

const WorkTicketTable = ({
    workTickets,
    updateWorkTicket,
    loading,
    offset,
    lastRowRef,
}) => {
    const navigate = useNavigate();
    const {setAsideChildren} = useContext(AppContext);

    const dropDownItems = (
        aside,
        workTicket,
    ) => {
        return <>
            {!aside
                ? <Dropdown.Item onClick={
                    () => openAside(workTicket)
                }>{'View Work Details'}</Dropdown.Item>
                : null}
            <Dropdown.Item onClick={() => openCreateEditWorkTicket(workTicket)}>
                {'Edit Work'}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openGroupAside(workTicket)}>
                {'Group Work'}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openAssignAside(workTicket)}>
                {'Assign Technicians'}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/work-tickets/'
                + `${workTicket?.work_ticket_id}`
                + '/bom')}
            >
                {'View Bill of Materials'}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => openManageUnitsAside(workTicket)}
            >
                {'Manage Units'}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/work-tickets/'
                + `${workTicket?.work_ticket_id}/kits`)}
            >
                {'Manage Kits'}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/work-tickets/'
                + `${workTicket?.work_ticket_id}/unit-overview`)}
            >
                {'Unit Overview'}
            </Dropdown.Item>
        </>;
    };

    const openCreateEditWorkTicket = useCallback((workTicket) => {
        setAsideChildren(<WorkTicketCreateEdit
            workTicket={workTicket}
            updateWorkTicket={updateWorkTicket}
        />);
    }, [setAsideChildren, updateWorkTicket]);

    const openGroupAside = useCallback((workTicket) => {
        setAsideChildren(<WorkTicketGroupAside
            workTicket={workTicket}
            updateWorkTicket={updateWorkTicket}
        />);
    }, [setAsideChildren, updateWorkTicket]);

    const openNotesAside = useCallback((workTicket) => {
        setAsideChildren(<WorkTicketNotesAside
            workTicket={workTicket}
            updateWorkTicket={updateWorkTicket}
        />);
    }, [setAsideChildren, updateWorkTicket]);

    const openManageUnitsAside = useCallback((workTicket) => {
        setAsideChildren(<ManageUnitsAside workTicket={workTicket}/>);
    }, [setAsideChildren]);

    const openAssignAside = useCallback((workTicket) => {
        setAsideChildren(
            <WorkTicketAssignAside
                workTicket={workTicket}
                updateWorkTicket={updateWorkTicket}
            />,
        );
    }, [setAsideChildren, updateWorkTicket]);

    const openAside = (workTicket) => {
        setAsideChildren(<WorkTicketAside
            workTicket={workTicket}
            updateWorkTicket={updateWorkTicket}
            dropdownItems={dropDownItems}
        />);
    };

    return <>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} md={3} xl={2}>
                    {'Work#'}
                </TableCol>
                <TableCol xs={4} md={2} xl={1}>
                    {'Order#'}
                </TableCol>
                <TableCol
                    xs={2} md={3} xl={2}
                    className={'d-none d-md-block'}
                >
                    {'Customer'}
                </TableCol>
                <TableCol xs={2} xl={1} className={'d-none d-md-block'}>
                    {'Status'}
                </TableCol>
                <TableCol xs={1} className={'d-none d-xl-block'}>
                    {'Max Stage'}
                </TableCol>
                <TableCol xs={1} className={'text-center d-none d-xl-block'}>
                    {'Priority'}
                </TableCol>
                <TableCol xs={1} className={'text-center d-none d-xl-block'}>
                    {'Project'}
                </TableCol>
                <TableCol xs={1} className={'d-none d-xl-block'}>
                    {'Ship Date'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {workTickets?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Work found'}
                    </TableCol>
                </TableRow>
                : _.map(workTickets, (workTicket) =>
                    <TableRow key={workTicket?.work_ticket_id}>
                        <TableCol xs={4} md={3} xl={2}>
                            <Row>
                                <TableColInnerIcon>
                                    <WorkTicketDurationIndicator
                                        workTicket={workTicket}
                                    />
                                </TableColInnerIcon>
                                <TableCol className={'px-1'}>
                                    {workTicket?.work_ticket_number}
                                </TableCol>
                                {workTicket?.work_ticket_group?.member_count > 1
                                    && <TableColInnerIcon>
                                        <TableIcon
                                            className={'float-end me-0'}
                                            src={workTicketGroupIcon}
                                            alt={'Group'}
                                            onClick={() =>
                                                openGroupAside(workTicket)}
                                        />
                                    </TableColInnerIcon>}
                            </Row>
                        </TableCol>
                        <TableCol xs={4} md={2} xl={1}>
                            {workTicket?.order_number}
                        </TableCol>
                        <TableCol
                            xs={2} md={3} xl={2}
                            className={'d-none d-md-block'}
                        >
                            {workTicket?.customer?.label}
                        </TableCol>
                        <TableCol xs={2} xl={1} className={'d-none d-md-block'}>
                            {workTicket?.status}
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-xl-block'}>
                            {workTicket?.max_stage}
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-xl-block'}>
                            <Form.Check className={'text-center'}>
                                <Form.Check.Input
                                    type={'checkbox'}
                                    readOnly={true}
                                    checked={workTicket?.is_priority ?? false}
                                />
                            </Form.Check>
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-xl-block'}>
                            <Form.Check className={'text-center'}>
                                <Form.Check.Input
                                    type={'checkbox'}
                                    readOnly={true}
                                    checked={workTicket?.is_project ?? false}
                                />
                            </Form.Check>
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-xl-block'}>
                            {workTicket?.ship_date
                                ? DateTime
                                    .fromISO(workTicket.ship_date)
                                    .toLocaleString()
                                : ''}
                        </TableCol>
                        <TableColActions xs={4} md={2} xl={2}>
                            <TableIcon
                                src={partIcon}
                                alt={'Parts'}
                                onClick={() => navigate(
                                    '/work-tickets/'
                                        + workTicket?.work_ticket_id
                                        + '/bom',
                                )}
                            />
                            <TableIconText $characters={3}>
                                {workTicket?.config_count}
                            </TableIconText>
                            <TableIcon
                                src={noteIcon}
                                alt={'Notes'}
                                onClick={() => openNotesAside(workTicket)}
                            />
                            <TableIconText $characters={2}>
                                {workTicket?.note_count}
                            </TableIconText>
                            <Dropdown>
                                <Dropdown.Toggle as={DropdownToggle}/>
                                <Dropdown.Menu>
                                    {dropDownItems(false, workTicket)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </TableColActions>
                    </TableRow>)
            }
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default WorkTicketTable;
