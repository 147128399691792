import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react';
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import addIcon from '^assets/images/add.svg';
import refreshIcon from '^assets/images/refresh.svg';
import {PageActionFilter, PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import WorkTicketCreateEdit from '^pages/workTicket/workTicketCreateEdit';
import WorkTicketTable from '^pages/workTicket/workTicketTable';
import WorkTicketFilter from '^pages/workTicket/aside/workTicketFilter';
import {Breadcrumb} from 'react-bootstrap';
import {
    defaultSearchParams,
    defaultSearchParamsObj,
} from '^config/defaultSearchParams';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';

const WorkTicketList = () => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const {setAsideChildren} = useContext(AppContext);

    const [workTickets, setWorkTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const [searchParams] = useSearchParams();
    const [offset, setOffset] = useState(null);

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    const getWorkTickets = useCallback(async (offset) => {
        let response;
        setLoading(true);

        if (_.keys(paramObject).length === 1
            && _.has(paramObject, 'serial_number')
        ) {
            const unitsResponse = await api.get('/units', {
                params: {serial_number: paramObject['serial_number']},
            });

            response = await api.get('/work-tickets', {
                params: {
                    offset,
                    limit,
                    work_ticket_number: _.chain(unitsResponse)
                        .get('data.results')
                        .map('work_ticket.work_ticket_number')
                        .join(',')
                        .value(),
                    facility_id: userFacility,
                },
            });
        } else {
            response = await api.get('/work-tickets', {
                params: {
                    offset,
                    limit,
                    ...paramObject,
                    facility_id: userFacility,
                },
            });
        }

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setWorkTickets((prevState) => _.uniqBy(
            [...prevState, ...results],
            'work_ticket_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, paramObject, userFacility]);

    useEffect(() => {
        if (api && userFacility) {
            setWorkTickets([]);
            setOffset(null);
            getWorkTickets(0);
        }
    }, [
        api,
        paramObject,
        userFacility,
        getWorkTickets,
    ]);

    const lastRowRef = usePagination(offset, getWorkTickets);

    const updateWorkTicket = useCallback((workTicket) => {
        setWorkTickets((prevState) => {
            const workTicketId = workTicket?.work_ticket_id;
            const existing = _.find(
                prevState,
                {work_ticket_id: workTicketId},
            );

            const newWorkTickets = existing
                ? _.map(prevState, (prev) =>
                    workTicketId === prev.work_ticket_id
                        ? workTicket
                        : prev,
                ) : [workTicket, ...prevState];

            const customerFilter = paramObject?.customer_id;
            const statusFilter = paramObject?.status;
            const targetMetricsStatusFilter = paramObject
                ?.target_metrics_overall_status;
            const inboundFilter = paramObject?.inbound_storage_location_id;
            const outboundFilter = paramObject?.outbound_storage_location_id;
            const assignedFilter = paramObject?.is_assigned;
            const priorityFilter = paramObject?.priority;
            const projectFilter = paramObject?.project;
            const poolFilter = paramObject?.pool;
            const shipDateFilter = paramObject?.shipDate;

            return _.chain(newWorkTickets)
                .filter((workTicket) => customerFilter
                    ? workTicket?.customer?.customer_id === customerFilter
                    : true)
                .filter((workTicket) => statusFilter
                    ? _.includes(_.split(statusFilter, ','), workTicket?.status)
                    : true)
                .filter((workTicket) => targetMetricsStatusFilter
                    ? _.includes(
                        _.split(targetMetricsStatusFilter, ','),
                        workTicket?.target_metrics?.overall_status,
                    )
                    : true)
                .filter((workTicket) => inboundFilter
                    ? workTicket?.inbound_storage_location
                        ?.storage_location_id === inboundFilter
                    : true)
                .filter((workTicket) => outboundFilter
                    ? workTicket?.outbound_storage_location
                        ?.storage_location_id === outboundFilter
                    : true)
                .filter((workTicket) => {
                    switch (assignedFilter) {
                        case '0':
                            return workTicket.is_assigned === false;
                        case '1':
                            return workTicket.is_assigned === true;
                        default:
                            return true;
                    }
                })
                .filter((workTicket) => {
                    switch (priorityFilter) {
                        case '0':
                            return workTicket.is_priority === false;
                        case '1':
                            return workTicket.is_priority === true;
                        default:
                            return true;
                    }
                })
                .filter((workTicket) => {
                    switch (projectFilter) {
                        case '0':
                            return workTicket.is_project === false;
                        case '1':
                            return workTicket.is_project === true;
                        default:
                            return true;
                    }
                })
                .filter((workTicket) => poolFilter
                    ? _.includes(_.map(
                        _.split(poolFilter, ','),
                        (val) => val === '' ? null : val,
                    ), workTicket?.pool)
                    : true)
                .filter((workTicket) => shipDateFilter
                    ? workTicket?.ship_date === shipDateFilter
                    : true)
                .value();
        });
    }, [paramObject]);

    const openCreateEditWorkTicket = useCallback((workTicket) => {
        setAsideChildren(
            <WorkTicketCreateEdit
                workTicket={workTicket}
                updateWorkTicket={updateWorkTicket}
            />,
        );
    }, [setAsideChildren, updateWorkTicket]);

    const openFilter = useCallback(() => {
        setAsideChildren(<WorkTicketFilter/>);
    }, [setAsideChildren]);

    return <>
        <PageHeader
            title={'Work'}
            actionButtons={<>
                <PageActionIcon
                    src={refreshIcon}
                    alt={'Refresh'}
                    disabled={loading}
                    onClick={() => {
                        setWorkTickets([]);
                        setOffset(null);
                        getWorkTickets(0);
                    }}
                />
                <PageActionFilter
                    onClick={openFilter}
                    filterParams={paramObject}
                    defaultFilterParams={defaultSearchParamsObj.workTicket}
                />
                <PageActionIcon
                    src={addIcon}
                    alt={'Create'}
                    disabled={!userFacility}
                    onClick={() => openCreateEditWorkTicket(null)}
                />
            </>}
        >
            {(() => {
                switch (_.keys(paramObject).length) {
                    case 0:
                        return (
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => navigate('/')}>
                                    {userFacilityLabel}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        );
                    case 1:
                        if (_.has(paramObject, 'work_ticket_number')
                            || _.has(paramObject, 'shipment_number')
                            || _.has(paramObject, 'order_number')
                            || _.has(paramObject, 'serial_number')) {
                            const [key] = _.keys(paramObject);
                            return (
                                <Breadcrumb>
                                    <Breadcrumb.Item
                                        onClick={() => navigate('/')}>
                                        {userFacilityLabel}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        onClick={() => navigate({
                                            pathname: '/work-tickets',
                                            search: defaultSearchParams
                                                .workTicket,
                                        })}>
                                        {'Work'}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => navigate({
                                        pathname: '/work-tickets',
                                        search: `?${createSearchParams({
                                            ...paramObject,
                                        })}`,
                                    })}>
                                        {paramObject?.[key] ?? '…'}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            );
                        }
                        // falls through
                    default:
                        return (
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => navigate('/')}>
                                    {userFacilityLabel}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => navigate({
                                        pathname: '/work-tickets',
                                        search: defaultSearchParams.workTicket,
                                    })}>
                                    {'Work'}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        );
                }
            })()}
        </PageHeader>
        <WorkTicketTable
            updateWorkTicket={updateWorkTicket}
            workTickets={workTickets}
            loading={loading}
            offset={offset}
            lastRowRef={lastRowRef}
        />
    </>;
};

export default WorkTicketList;
