import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {Button, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';

const ExtensionCreateEdit = ({
    extension,
    updateExtension,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [active, setActive] = useState(true);
    const [label, setLabel] = useState('');

    const extensionId = extension?.extension_id;

    useEffect(() => {
        setActive(extension?.active ?? true);
        setLabel(extension?.label ?? '');
    }, [extension?.active, extension?.label]);

    const createEditExtension = useCallback(async () => {
        setProcessing(true);
        const body = {active, label};

        const response = extensionId
            ? await api.patch(`/extensions/${extensionId}`, body)
            : await api.post('/extensions', body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateExtension(response?.data);
        setAsideChildren(null);
    }, [
        active,
        api,
        extensionId,
        label,
        setAsideChildren,
        updateExtension,
    ]);

    return <>
        <AsideHeader>
            {extensionId
                ? `Edit Extension "${extension?.label ?? ''}"`
                : 'Create New Extension'}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            createEditExtension();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    type={'checkbox'}
                    label={'Active'}
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                ></Form.Check>
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Label'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            {extensionId && <Row className={'text-center py-2'}>
                <div
                    className={'text-decoration-underline text-primary'}
                    role={'button'}
                    onClick={() => {
                        setAsideChildren(null);
                        navigate(`/manage-extensions/${extensionId}`);
                    }}
                >
                    {'Edit Extension Code'}
                </div>
            </Row>}
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing || !label}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default ExtensionCreateEdit;
