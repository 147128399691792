import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import CustomAccordion from '^common/customAccordion';
import {Accordion, Form} from 'react-bootstrap';
import {FormSelect} from '^common/formSelect';
import SerializedCard
    from '^pages/workTicket/commonAssemblyKitting/serializedCard';
import NonSerializedCard
    from '^pages/workTicket/commonAssemblyKitting/nonSerializedCard';
import {toggleAccordion}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';

const AssemblyKittingFilter = ({
    unitId,
    units,
    barcodeUnits,
    filters,
    setters,
    formData,
    setFormData,
    barcodeFormData,
    setBarcodeFormData,
    descendantTrees,
    handleSubmit,
    partConfigOptions,
}) => {
    const skuInputRef = useRef(null);
    const [partConfigInput, setPartConfigInput] = useState('');
    const [barcodeType, setBarcodeType] = useState(null);

    const [serializedOptions, setSerializedOptions] = useState([]);
    const [nonSerializedOptions, setNonSerializedOptions] = useState([]);

    const [filteredAssembledUnits, setFilteredAssembledUnits] = useState([]);
    const [filteredAvailableUnits, setFilteredAvailableUnits] = useState([]);

    const {partConfigFilter, serializedFilter, nonSerializedFilter} = filters;
    const {
        setPartConfigFilter,
        setSerializedFilter,
        setNonSerializedFilter,
        setExistingKey,
        setAvailableKey,
    } = setters;

    const focusInput = () => {
        if (skuInputRef.current) {
            skuInputRef.current.focus();
        }
    };

    useEffect(() => {
        if (partConfigOptions) {
            focusInput();
        }
    }, [partConfigOptions]);

    useEffect(() => {
        if (_.includes(partConfigFilter, 'Non-Serialized')
            || barcodeType === 'Non-Serialized'
        ) {
            if (nonSerializedOptions.length === 1) {
                setNonSerializedFilter(nonSerializedOptions[0].value);
                if (serializedFilter) {
                    setSerializedFilter(null);
                }
                toggleAccordion(setExistingKey, null);
                toggleAccordion(setAvailableKey, null);
            }
        }
    }, [
        partConfigFilter,
        nonSerializedOptions,
        setNonSerializedFilter,
        serializedFilter,
        setSerializedFilter,
        setExistingKey,
        setAvailableKey,
        barcodeType,
    ]);

    useEffect(() => {
        const setOptions = (units, isSerialized, partConfig) => {
            if (isSerialized) {
                const availableUnits = units.available[partConfig];
                const assembledUnits = units.combined[partConfig];

                const filteredUnits
                    = [...(availableUnits ?? []), ...(assembledUnits ?? [])];
                setSerializedOptions(_.map(filteredUnits, (unit) => ({
                    label: unit.serial_number,
                    value: {...unit},
                })));
            } else {
                const availableOptions = units.available[partConfig];
                const assembledOptions = units.combined[partConfig];
                const availableKeys = availableOptions
                    ? Object.keys(availableOptions) || []
                    : [];
                const assembledKeys = assembledOptions
                    ? Object.keys(assembledOptions) || []
                    : [];
                const options
                    = [...new Set([...availableKeys, ...assembledKeys])];
                setNonSerializedOptions(_.map(options, (option) => ({
                    label: option,
                    value: {
                        label: option,
                        part_config: partConfig,
                    },
                })));
            }
        };

        if (units?.combined && units?.available && partConfigFilter) {
            const isSerialized = _.includes(partConfigFilter,
                '(Serialized)');
            const isNonSerialized = _.includes(partConfigFilter,
                '(Non-Serialized)');

            if (isSerialized || isNonSerialized) {
                setOptions(units, isSerialized, partConfigFilter);
            } else {
                if (_.isArray(barcodeUnits.available[partConfigFilter])
                    || _.isArray(barcodeUnits.combined[partConfigFilter])
                ) {
                    setOptions(barcodeUnits, true, partConfigFilter);
                } else {
                    setBarcodeType('Non-Serialized');
                    setOptions(barcodeUnits, false, partConfigFilter);
                }
            }
        }
    }, [units, barcodeUnits, partConfigFilter]);

    useEffect(() => {
        const getFilteredUnits = (unitsObj, partConfig, label) => {
            if (!unitsObj || !partConfig || !label) return [];
            const unitsForPartConfig = unitsObj[partConfig];
            return unitsForPartConfig ? unitsForPartConfig[label] : [];
        };

        if (nonSerializedFilter) {
            const {part_config: partConfig, label} = nonSerializedFilter;

            if (units?.combined) {
                setFilteredAssembledUnits(
                    getFilteredUnits(units.combined, partConfig, label),
                );
            }
            if (units?.available) {
                setFilteredAvailableUnits(
                    getFilteredUnits(units.available, partConfig, label),
                );
            }
            if (!_.includes(partConfig, 'Non-Serialized')
                && !_.isEmpty(barcodeUnits)) {
                if (!_.isEmpty(barcodeUnits.combined)) {
                    setFilteredAssembledUnits(
                        getFilteredUnits(
                            barcodeUnits.combined,
                            partConfig,
                            label,
                        ),
                    );
                }
                if (!_.isEmpty(barcodeUnits.available)) {
                    setFilteredAvailableUnits(
                        getFilteredUnits(
                            barcodeUnits.available,
                            partConfig,
                            label,
                        ),
                    );
                }
            }
        }
    }, [nonSerializedFilter, units, barcodeUnits]);

    const filteredPartConfigOptions = _.filter(
        partConfigOptions,
        ({label}) => {
            const partInputRegExp = _.escapeRegExp(partConfigInput);
            const regex = new RegExp(`^${partInputRegExp}(?:$| .+)`);

            return partConfigInput
                ? regex.test(label)
                : false;
        },
    );

    return (<CustomAccordion key={'Filter'}
        defaultActiveKey={'0'}>
        <Accordion.Item eventKey={'0'}>
            <Accordion.Header>{'Filter'}</Accordion.Header>
            <Accordion.Body>
                <Form.Group>
                    <Form.Text>{'SKU'}</Form.Text>
                    <FormSelect
                        ref={skuInputRef}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        value={_.find(partConfigOptions,
                            {value: partConfigFilter})
                            ?? null}
                        options={filteredPartConfigOptions}
                        onChange={(e) => {
                            setPartConfigFilter(e?.value ?? null);
                            setSerializedFilter(null);
                            setNonSerializedFilter(null);
                            setBarcodeType(null);
                        }}
                        onInputChange={setPartConfigInput}
                        noOptionsMessage={({inputValue}) => inputValue
                            ? `SKU "${inputValue}" not found`
                            : 'Enter SKU'}
                    />
                    {partConfigFilter
                        && ((_.includes(partConfigFilter, 'Non-Serialized')
                                || barcodeType === 'Non-Serialized')
                            ? <>
                                <Form.Text>{'Non-Serialized Units'}</Form.Text>
                                <FormSelect
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    value={_.find(nonSerializedOptions,
                                        {label: nonSerializedFilter?.label})
                                        ?? null}
                                    options={nonSerializedOptions}
                                    onChange={(e) => {
                                        setNonSerializedFilter(e?.value
                                            ?? null);
                                        if (serializedFilter) {
                                            setSerializedFilter(null);
                                        }
                                        toggleAccordion(setExistingKey, null);
                                        toggleAccordion(setAvailableKey, null);
                                    }}
                                />
                            </>
                            : <>
                                <Form.Text>{'Serial Number'}</Form.Text>
                                <FormSelect
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    value={_.find(serializedOptions, (option) =>
                                        option?.value?.serial_number
                                            === serializedFilter?.serial_number)
                                        ?? null
                                    }
                                    options={serializedOptions}
                                    onChange={(e) => {
                                        setSerializedFilter(e?.value ?? null);
                                        if (nonSerializedFilter) {
                                            setNonSerializedFilter(null);
                                        }
                                        toggleAccordion(setExistingKey, null);
                                        toggleAccordion(setAvailableKey, null);
                                    }}
                                />
                            </>
                        )}
                </Form.Group>
                {!_.isNil(serializedFilter)
                    && <SerializedCard
                        unit={serializedFilter}
                        unitId={unitId}
                        descendantTrees={descendantTrees}
                        handleSubmit={handleSubmit}
                    />
                }
                {!_.isNil(nonSerializedFilter)
                    && <NonSerializedCard
                        combined={true}
                        label={nonSerializedFilter?.label}
                        partConfig={nonSerializedFilter?.part_config}
                        formData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? formData
                                : barcodeFormData
                        }
                        setFormData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? setFormData
                                : setBarcodeFormData
                        }
                        units={filteredAssembledUnits}
                        handleSubmit={handleSubmit}
                    />
                }
                {!_.isNil(nonSerializedFilter)
                    && <NonSerializedCard
                        combined={false}
                        label={nonSerializedFilter?.label}
                        partConfig={nonSerializedFilter?.part_config}
                        formData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? formData
                                : barcodeFormData
                        }
                        setFormData={
                            _.includes(nonSerializedFilter?.part_config,
                                'Non-Serialized')
                                ? setFormData
                                : setBarcodeFormData
                        }
                        units={filteredAvailableUnits}
                        handleSubmit={handleSubmit}
                    />
                }
            </Accordion.Body>
        </Accordion.Item>
    </CustomAccordion>);
};

export default AssemblyKittingFilter;
