import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {defaultSearchParams} from '^config/defaultSearchParams';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import unitIcon from '^assets/images/unit.svg';
import {DropdownToggle} from '^common/dropdownToggle';
import {PageActionFilter, PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon, TableIconText} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import {Breadcrumb, Dropdown, Form, Spinner} from 'react-bootstrap';
import PartFilterAside from '^pages/part/partFilterAside';
import ManageUnitsAside from './manageUnits/manageUnitsAside';
import WorkTicketBOMAside from './aside/workTicketBOMAside';
import WorkTicketBOMCreateAside from './aside/workTicketBOMCreateAside';

const WorkTicketBOMList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const {work_ticket_id: workTicketId} = useParams();
    const navigate = useNavigate();

    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const [workTicket, setWorkTicket] = useState(null);
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(true);

    const [searchParams] = useSearchParams();
    const isService = searchParams.get('is_service');

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    useEffect(() => {
        const getBOM = async () => {
            setLoading(true);
            const response = await api.get(`/work-tickets/${workTicketId}/bom`);
            setLoading(false);

            if (isResponseError(response)) {
                return;
            }

            setParts(response?.data);
        };

        const getWorkTicket = async () => {
            const response = await api.get(`/work-tickets/${workTicketId}`);

            if (isResponseError(response)) {
                return;
            }

            setWorkTicket(response?.data);
        };

        if (api && workTicketId) {
            getWorkTicket();
            getBOM();
        }
    }, [api, workTicketId]);

    const dropdownItems = (aside, part) => {
        return <>
            {!aside
                ? <Dropdown.Item
                    onClick={() => openAside(part)}
                >
                    {'View Part Details'}
                </Dropdown.Item>
                : null}
            <Dropdown.Item
                onClick={() => {
                    navigate(
                        '/work-tickets'
                       + `/${workTicketId}`
                       + `/${part?.config_id}`
                       + '/units');
                    if (aside) setAsideChildren(null);
                }}
            >
                {'View Units'}
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => openManageUnitsAside(workTicket, part)}
            >
                {'Manage Units'}
            </Dropdown.Item>
        </>;
    };

    const openAside = (part) => {
        setAsideChildren(
            <WorkTicketBOMAside
                part={part}
                dropdownItems={dropdownItems}
            />,
        );
    };

    const openBomCreateAside = useCallback(() => {
        setAsideChildren(<WorkTicketBOMCreateAside
            workTicket={workTicket}
            setBom={setParts}
        />);
    }, [setAsideChildren, workTicket]);

    const openManageUnitsAside = useCallback((workTicket, part) => {
        setAsideChildren(<ManageUnitsAside
            workTicket={workTicket}
            defaultPart={part}
        />);
    }, [setAsideChildren]);

    const filteredParts = _.filter(parts, (part) => {
        switch (isService) {
            case '0':
                return part?.is_service === false;
            case '1':
                return part?.is_service === true;
            default:
                return true;
        }
    });

    return <>
        <PageHeader
            title={'Bill of Materials'}
            actionButtons={<>
                <PageActionFilter
                    filterParams={paramObject}
                    onClick={() => setAsideChildren(<PartFilterAside/>)}
                    defaultFilterParams={null}
                />
                <PageActionIcon
                    src={addIcon}
                    alt={'Create'}
                    onClick={workTicket && workTicket?.config_count === 0
                        ? () => openBomCreateAside()
                        : null}
                />
            </>}
        >
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/')}>
                    {userFacilityLabel}
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate({
                    pathname: '/work-tickets',
                    search: defaultSearchParams.workTicket,
                })}>
                    {'Work'}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {workTicket?.work_ticket_number ?? '…'}
                </Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} md={2}>
                    {'SKU'}
                </TableCol>
                <TableCol xs={4} md={2}>
                    {'Manufacturer Part #'}
                </TableCol>
                <TableCol xs={2} className={'d-none d-md-block'}>
                    {'Type'}
                </TableCol>
                <TableCol xs={2} className={'d-none d-md-block'}>
                    {'Desc'}
                </TableCol>
                <TableCol xs={1} className={'text-center d-none d-md-block'}>
                    {'S/N'}
                </TableCol>
                <TableCol xs={1} className={'text-center d-none d-md-block'}>
                    {'Service'}
                </TableCol>
            </TableRow>
            {loading && <Spinner/>}
            {filteredParts?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Parts found'}
                    </TableCol>
                </TableRow>
                : _.map(filteredParts, (part, index) =>
                    <TableRow key={`part-${index}`}>
                        <TableCol xs={4} md={2}>
                            {`${part.part_number}-${part.config_label}`}
                        </TableCol>
                        <TableCol xs={4} md={2}>
                            {part.manufacturer_part_number ?? ''}
                        </TableCol>
                        <TableCol xs={2} className={'d-none d-md-block'}>
                            {part.type}
                        </TableCol>
                        <TableCol xs={2} className={'d-none d-md-block'}>
                            {part.description}
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-md-block'}>
                            <Form.Check
                                className={'text-center'}
                                checked={part.serialized}
                                readOnly={true}
                            />
                        </TableCol>
                        <TableCol xs={1} className={'d-none d-md-block'}>
                            <Form.Check
                                className={'text-center'}
                                checked={part.is_service}
                                readOnly={true}
                            />
                        </TableCol>
                        <TableColActions xs={4} md={2}>
                            <TableIcon
                                src={unitIcon}
                                alt={'Parts'}
                                $disabled={part.quantity === 0}
                                onClick={() => navigate(
                                    '/work-tickets'
                                + `/${workTicketId}`
                                + `/${part?.config_id}`
                                + '/units',
                                )}
                            />
                            <TableIconText $characters={5}>
                                {part.quantity}
                            </TableIconText>
                            <Dropdown>
                                <Dropdown.Toggle as={DropdownToggle}/>
                                <Dropdown.Menu>
                                    {dropdownItems(false, part)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </TableColActions>
                    </TableRow>,
                )}
        </TableContainer>
    </>;
};

export default WorkTicketBOMList;
