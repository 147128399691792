import React from 'react';
import {useLocalStorage} from 'usehooks-ts';
import {PageHeader} from '^common/pageHeader';
import HomeWidget from './homeWidget';
import MyAssignmentsList from './myAssignments/myAssignmentsList';

const HomeContainer = () => {
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    return <>
        <PageHeader title={'Home'}/>
        <HomeWidget
            name={'my_assignments'}
            title={`My Assignments (${userFacilityLabel})`}
        >
            <MyAssignmentsList/>
        </HomeWidget>
    </>;
};

export default HomeContainer;
