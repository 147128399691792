import React from 'react';
import styled from 'styled-components';
import {Container, Row} from 'react-bootstrap';
import warningIcon from '^assets/images/warning.svg';

const WarningIcon = styled.img.attrs(() => ({
    src: warningIcon,
}))`
    height: 10rem;
    width: 10rem;
`;

const NotFound = () => {
    return <Container fluid={true} className={'my-3'}>
        <Row className={'text-center justify-content-center'}>
            <WarningIcon/>
            <h1 className={'text-primary fw-bold'}>
                {'Page Not Found'}
            </h1>
        </Row>
    </Container>;
};

export default NotFound;
