import React, {useState} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import externalLink from '^assets/images/externalLink.svg';
import labTrackerLogo from '^assets/images/labTrackerLogo.svg';
import nLogo from '^assets/images/nLogo.svg';
import {Navbar, OverlayTrigger, Popover} from 'react-bootstrap';

const NavLogo = styled.img.attrs(() => ({
    className: 'ms-2',
}))`
  height: 2rem;
`;

const ExternalLinkIcon = styled.img.attrs(() => ({
    className: 'ms-1 mb-1',
    src: externalLink,
    alt: 'Link',
}))`
  height: 1rem;
  width: 1rem;
`;

const BrandLinkDiv = styled.div.attrs(() => ({
    className: _.join([
        'p-3',
        'rounded',
        'text-primary',
        'text-center',
        'fw-bold',
    ], ' '),
    role: 'button',
}))`
  &:hover {
    background-color: var(--bs-secondary);
  }
`;

const BrandLink = ({
    label,
    href,
    setShowLinksOverlay,
}) => {
    return <BrandLinkDiv onClick={() => {
        setShowLinksOverlay(false);
        window.open(href, '_blank');
    }}>
        {label}
        <ExternalLinkIcon
            className={'d-inline-block'}
            src={externalLink}
            alt={'Link'}
        />
    </BrandLinkDiv>;
};

const TopNavBrand = () => {
    const [showLinksOverlay, setShowLinksOverlay] = useState(false);

    return <Navbar.Brand>
        <OverlayTrigger
            trigger={'click'}
            placement={'bottom'}
            show={showLinksOverlay}
            onToggle={(val) => setShowLinksOverlay(val)}
            rootClose={true}
            overlay={<Popover className={'mw-100'}>
                <Popover.Body className={'p-1'}>
                    <BrandLink
                        label={'FTM'}
                        href={'https://ftm.nterprise.com/'}
                        setShowLinksOverlay={setShowLinksOverlay}
                    />
                    <BrandLink
                        label={'CIMS'}
                        href={'https://cims.nterprise.com'}
                        setShowLinksOverlay={setShowLinksOverlay}
                    />
                </Popover.Body>
            </Popover>}
        >
            <NavLogo role={'button'} src={nLogo} alt={'n Logo'}/>
        </OverlayTrigger>
        <NavLogo
            className={'d-none d-sm-inline'}
            src={labTrackerLogo}
            alt={'LabTracker Logo'}
        />
    </Navbar.Brand>;
};

export default TopNavBrand;
