import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {useScrollButtons} from '^utilities/useScrollButtons';
import {PageHeader} from '^common/pageHeader';
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import ByUnitKitTab from './byUnitKit/byUnitKitTab';
import ByWorkTicketTab from './byWorkTicket/byWorkTicketTab';

const ActionTextSection = styled(Col).attrs(() => ({
    className: 'text-end',
}))`
    min-height: 3rem;
`;

const EXCLUDE_STAGES = ['Rolled-Back'];

const Packing = () => {
    const api = useContext(ApiContext);

    useScrollButtons();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    const [filterStages, setFilterStages] = useState([]);
    const [selectedTab, setSelectedTab] = useState('work_ticket');
    const [actionButtons, setActionButtons] = useState(null);
    const [actionText, setActionText] = useState(null);

    useEffect(() => {
        switch (true) {
            case !!searchParams.get('serial_number'):
            case !!searchParams.get('kit_label'):
                setSelectedTab('unit');
                return;
            case !!searchParams.get('work_ticket_number'):
                setSelectedTab('work_ticket');
                return;
            default:
                // no-op
        }
    }, [searchParams]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setFilterStages(_.difference(response?.data, EXCLUDE_STAGES));
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    return <>
        <PageHeader
            title={'Packing'}
            actionButtons={actionButtons}
        >
            <Row>
                <Col xs={6}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/')}>
                            {userFacilityLabel}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {'Packing'}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <ActionTextSection xs={6}>
                    {actionText}
                </ActionTextSection>
            </Row>
        </PageHeader>
        <Container fluid={true} className={'mb-2'}>
            <Row className={'justify-content-center'}>
                <Col xs={12} md={6} xl={4}>
                    <ButtonGroup size={'sm'} className={'w-100 my-2'}>
                        <Button
                            onClick={() => {
                                if (selectedTab !== 'work_ticket') {
                                    setSearchParams({});
                                    setSelectedTab('work_ticket');
                                }
                            }}
                            variant={selectedTab === 'work_ticket'
                                ? 'primary'
                                : 'outline-primary'}
                        >
                            {'Search by Work Number'}
                        </Button>
                        <Button
                            onClick={() => {
                                if (selectedTab !== 'unit') {
                                    setSearchParams({});
                                    setSelectedTab('unit');
                                }
                            }}
                            variant={selectedTab === 'unit'
                                ? 'primary'
                                : 'outline-primary'}
                        >
                            {'Search by Unit/Kit'}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {selectedTab === 'work_ticket' && <ByWorkTicketTab
                setActionButtons={setActionButtons}
                setActionText={setActionText}
                filterStages={filterStages}
            />}
            {selectedTab === 'unit' && <ByUnitKitTab
                setActionButtons={setActionButtons}
                setActionText={setActionText}
                filterStages={filterStages}
            />}
        </Container>
    </>;
};

export default Packing;
