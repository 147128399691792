import React, {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {Duration} from 'luxon';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import AsideGroupHeader from '^common/asideGroupHeader';
import {Container, Row, Col, Dropdown} from 'react-bootstrap';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';

const WorkTicketBOMAside = ({
    part,
    dropdownItems,
}) => {
    const api = useContext(ApiContext);
    const [serviceMetrics, setServiceMetrics] = useState(null);

    const isService = part?.is_service;
    const configId = part?.config_id;

    useEffect(() => {
        const getConfig = async () => {
            const response = await api.get(`/configs/${configId}`);

            if (isResponseError(response)) {
                return;
            }

            const data = response?.data;
            const workDurationLimit = data?.work_ticket_duration_limit;
            const unitDurationLimit = data?.unit_duration_limit;
            const unitDurationCost = data?.unit_duration_cost;

            setServiceMetrics({
                'Work Duration Limit': _.isEmpty(workDurationLimit)
                    ? 'None'
                    : Duration
                        .fromObject(workDurationLimit)
                        .shiftTo('days', 'hours', 'minutes')
                        .toHuman({unitDisplay: 'short'}),
                'Unit Duration Limit': _.isEmpty(unitDurationLimit)
                    ? 'None'
                    : Duration
                        .fromObject(unitDurationLimit)
                        .shiftTo('days', 'hours', 'minutes')
                        .toHuman({unitDisplay: 'short'}),
                'Unit Duration Cost': _.isNil(unitDurationCost)
                    ? 'None'
                    : new Intl.NumberFormat(
                        undefined, {
                            style: 'currency',
                            currency: unitDurationCost?.currency,
                        },
                    ).format(unitDurationCost?.value),
            });
        };

        if (!api) {
            return;
        }

        if (!isService) {
            return;
        }

        if (!configId) {
            return;
        }

        getConfig();
    }, [api, configId, isService]);

    const details = {
        'Type': part?.type,
        'Manufacturer Part Number': part?.manufacturer_part_number ?? 'N/A',
        'Description': part?.description,
        'Service': part?.is_service ? 'Yes' : 'No',
        'Serialized': _.capitalize(_.toString(part?.serialized)),
        'Quantity': part?.quantity,
    };

    return <>
        <AsideHeader
            className={'d-flex justify-content-between'}
        >
            <b>{`${part?.part_number}-${part?.config_label}`}</b>
            <Dropdown className={'mt-n1 pe-4'}>
                <Dropdown.Toggle
                    as={'img'}
                    role={'button'}
                    src={verticalEllipsis}
                    alt={'Dropdown Toggle'}
                    height={'20'}
                    width={'20'}
                />
                <Dropdown.Menu>
                    {dropdownItems(true, part)}
                </Dropdown.Menu>
            </Dropdown>
        </AsideHeader>
        <AsideGroupHeader>
            {'Standard Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(details, (value, key) => (
                <Row key={key}>
                    <Col>
                        {`${key}: `}
                        {value}
                    </Col>
                </Row>
            ))}
        </Container>
        {serviceMetrics && <>
            <AsideGroupHeader>
                {'Service Metrics Fields'}
            </AsideGroupHeader>
            <Container>
                {_.map(serviceMetrics, (value, key) => (
                    <Row key={key}>
                        <Col>
                            {`${key}: `}
                            {value}
                        </Col>
                    </Row>
                ))}
            </Container>
        </>}
    </>;
};

export default WorkTicketBOMAside;
