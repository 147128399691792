import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import externalLink from '^assets/images/externalLink.svg';
import history from '^assets/images/history.svg';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';
import {isResponseError} from '^utilities/isResponseError';
import AsideGroupHeader from '^common/asideGroupHeader';
import AsideHeader from '^common/asideHeader';
import HistoryAside from '^common/historyAside';
import {TableIcon} from '^common/tableIcon';
import {Container, Row, Col, Dropdown, Spinner} from 'react-bootstrap';
import WorkTicketNotesAside from '^pages/workTicket/aside/workTicketNotesAside';
import {DateTime, Duration} from 'luxon';

const WorkTicketAside = ({
    workTicket,
    updateWorkTicket,
    dropdownItems,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [ftmLinks, setFtmLinks] = useState([]);
    const [ftmLinksLoading, setFtmLinksLoading] = useState(true);

    const details = {
        'Shipment Number': workTicket?.shipment_number,
        'Order Number': workTicket?.order_number,
        'Customer': workTicket?.customer?.label,
        'Status': workTicket?.status,
        'Inbound Storage Location': workTicket
            ?.inbound_storage_location?.label,
        'Outbound Storage Location': workTicket
            ?.outbound_storage_location?.label,
        'Stage Counts':
            <div>{Object.entries(workTicket?.stage_count)
                .map(([stage, count]) => (
                    <li key={stage}>
                        {`${stage}: ${count}`}
                    </li>
                ))}
            </div>,
        'Priority': workTicket?.is_priority ? 'Yes' : 'No',
        'Project': workTicket?.is_project ? 'Yes' : 'No',
        'Pool': workTicket?.pool ?? 'N/A',
        'Ship Date': workTicket?.ship_date
            ? DateTime
                .fromISO(workTicket.ship_date)
                .toLocaleString()
            : 'N/A',
        'Start Time': workTicket?.start_time
            ? DateTime
                .fromISO(workTicket.start_time)
                .toLocaleString({
                    ...DateTime.DATETIME_SHORT,
                    timeZoneName: 'short',
                })
            : 'N/A',
        'End Time': workTicket?.end_time
            ? DateTime
                .fromISO(workTicket.end_time)
                .toLocaleString({
                    ...DateTime.DATETIME_SHORT,
                    timeZoneName: 'short',
                })
            : 'N/A',
        'Duration Limit': _.isEmpty(workTicket?.target_metrics?.duration_limit)
            ? 'None'
            : Duration
                .fromObject(workTicket?.target_metrics?.duration_limit)
                .shiftTo('days', 'hours', 'minutes')
                .toHuman({unitDisplay: 'short'}),
    };

    const openSelf = useCallback(() => {
        setAsideChildren(<WorkTicketAside
            workTicket={workTicket}
            updateWorkTicket={updateWorkTicket}
            dropdownItems={dropdownItems}
        />);
    }, [dropdownItems, setAsideChildren, updateWorkTicket, workTicket]);

    const historyAsideProps = {
        label: workTicket?.work_ticket_number ?? 'N/A',
        baseEndpoint: `/work-tickets/${workTicket.work_ticket_id}`,
        onReturn: openSelf,
    };

    const historyOnClicks = {
        'Status': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'status'}
            />);
        },
        'Inbound Storage Location': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'inbound_storage_location.label'}
            />);
        },
        'Outbound Storage Location': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'outbound_storage_location.label'}
            />);
        },
        'Priority': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'is_priority'}
            />);
        },
        'Project': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'is_project'}
            />);
        },
        'Ship Date': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'ship_date'}
            />);
        },
    };

    useEffect(() => {
        const fetchFtmLinks = async () => {
            const response = await api.get(
                `work-tickets/${workTicket.work_ticket_id}/ftm-links`,
            );

            setFtmLinksLoading(false);

            if (isResponseError(response)) {
                return;
            }

            setFtmLinks(response?.data);
        };

        if (!api) {
            return;
        }

        fetchFtmLinks();
    }, [api, workTicket.work_ticket_id]);

    const openNotesAside = useCallback((workTicket) => {
        setAsideChildren(<WorkTicketNotesAside
            workTicket={workTicket}
            updateWorkTicket={updateWorkTicket}
            onReturn={openSelf}
        />);
    }, [openSelf, setAsideChildren, updateWorkTicket]);

    return <>
        <AsideHeader
            className={'d-flex justify-content-between'}
        >
            <b>{`${workTicket?.work_ticket_number}`}</b>
            <Dropdown className={'mt-n1 pe-4'}>
                <Dropdown.Toggle
                    as={'img'}
                    role={'button'}
                    src={verticalEllipsis}
                    alt={'Dropdown Toggle'}
                    height={'20'}
                    width={'20'}
                />
                <Dropdown.Menu>
                    {dropdownItems(true, workTicket)}
                </Dropdown.Menu>
            </Dropdown>
        </AsideHeader>
        <AsideGroupHeader>
            {'Standard Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(details, (value, key) => (
                <Row key={key}>
                    <Col>
                        {`${key}: `}
                        {value}
                    </Col>
                    {_.has(historyOnClicks, key)
                        ? <Col xs={1} className={'text-end'}>
                            <TableIcon
                                src={history}
                                onClick={historyOnClicks[key]}
                            />
                        </Col> : null}
                </Row>
            ))}
        </Container>
        <AsideGroupHeader>
            {'FTM Links'}
            {ftmLinksLoading && <Spinner
                className={'ms-2'}
                size={'sm'}
            />}
        </AsideGroupHeader>
        <Container>
            {!ftmLinksLoading && _.size(ftmLinks) === 0 && <div>
                {'No FTM Links'}
            </div>}
            {_.map(ftmLinks, (link, idx) => <div key={idx}>
                <a
                    href={link.href}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    {link.label}
                </a>
            </div>)}
        </Container>
        <AsideGroupHeader>
            {'Notes'}
            <TableIcon
                src={externalLink}
                onClick={() => openNotesAside(workTicket)}
            />
        </AsideGroupHeader>
        <Container>
            <Row>
                <Col>
                    {`Notes Posted: ${workTicket?.note_count}`}
                </Col>
            </Row>
        </Container>
    </>;
};

export default WorkTicketAside;
