import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import deleteIcon from '^assets/images/crossWhite.svg';
import refreshIcon from '^assets/images/refresh.svg';
import uploadIcon from '^assets/images/upload.svg';
import {PageActionFilter, PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import {Breadcrumb, Form, Spinner} from 'react-bootstrap';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';
import StagedWorkTicketImport from './stagedWorkTicketImport';
import {DateTime} from 'luxon';
import StagedWorkTicketFilterAside
    from '^pages/stagedWorkTicket/stagedWorkTicketFilterAside';
import DeleteStagedWorkTicketModal
    from '^pages/stagedWorkTicket/deleteStagedWorkTicketModal';

const StagedWorkTicketList = () => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const {setAsideChildren} = useContext(AppContext);

    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const [stagedWorkTickets, setStagedWorkTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const [offset, setOffset] = useState(null);
    const [deleteStagedWorkTicket, setDeleteStagedWorkTicket] = useState(null);

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    const getStagedWorkTickets = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/staged-work-tickets', {
            params: {
                offset,
                limit,
                ...paramObject,
                facility_id: userFacility,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        setStagedWorkTickets((prevState) => _.uniqBy(
            [...prevState, ...response.data.results],
            'staged_work_ticket_id',
        ));

        setOffset(response?.data?.next_offset);
    }, [api, paramObject, userFacility]);

    useEffect(() => {
        if (api) {
            setStagedWorkTickets([]);
            setOffset(null);
            getStagedWorkTickets(0);
        }
    }, [api, getStagedWorkTickets]);

    const lastRowRef = usePagination(offset, getStagedWorkTickets);

    const onDelete = useCallback((stagedWorkTicket) => {
        const stagedWorkTicketId = stagedWorkTicket.staged_work_ticket_id;

        setStagedWorkTickets((prevState) => _.filter(
            prevState,
            (prev) => prev.staged_work_ticket_id !== stagedWorkTicketId,
        ));
    }, []);

    const onImport = useCallback((stagedWorkTickets) => {
        setStagedWorkTickets((prevState) => [
            ...prevState,
            ...stagedWorkTickets,
        ]);
    }, []);

    return <>
        <PageHeader
            title={'Staged Work'}
            actionButtons={<>
                <PageActionIcon
                    src={refreshIcon}
                    alt={'Refresh'}
                    disabled={loading}
                    onClick={() => {
                        setStagedWorkTickets([]);
                        setOffset(null);
                        getStagedWorkTickets(0);
                    }}
                />
                <PageActionFilter
                    onClick={() => setAsideChildren(
                        <StagedWorkTicketFilterAside/>,
                    )}
                    filterParams={paramObject}
                    defaultFilterParams={
                        defaultSearchParamsObj.stagedWorkTicket}
                />
                <PageActionIcon
                    src={uploadIcon}
                    alt={'Import'}
                    onClick={() => setAsideChildren(<StagedWorkTicketImport
                        onImport={onImport}
                    />)}
                />
            </>}
        >
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/')}>
                    {userFacilityLabel}
                </Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} lg={2}>
                    {'Work#'}
                </TableCol>
                <TableCol xs={2} lg={1} className={'text-center'}>
                    {'Priority'}
                </TableCol>
                <TableCol xs={2} lg={1} className={'text-center'}>
                    {'Project'}
                </TableCol>
                <TableCol xs={3} lg={2}>
                    {'Ship Date'}
                </TableCol>
                <TableCol xs={5} className={'d-none d-lg-block'}>
                    {'Group Work#(s)'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {stagedWorkTickets?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Staged Work found'}
                    </TableCol>
                </TableRow>
                : _.map(stagedWorkTickets, (stagedWorkTicket) =>
                    <TableRow key={stagedWorkTicket.staged_work_ticket_id}>
                        <TableCol xs={4} lg={2}>
                            {stagedWorkTicket?.work_ticket_number}
                        </TableCol>
                        <TableCol xs={2} lg={1}>
                            <Form.Check className={'text-center'}>
                                <Form.Check.Input
                                    type={'checkbox'}
                                    readOnly={true}
                                    checked={stagedWorkTicket
                                        ?.is_priority ?? false}
                                />
                            </Form.Check>
                        </TableCol>
                        <TableCol xs={2} lg={1}>
                            <Form.Check className={'text-center'}>
                                <Form.Check.Input
                                    type={'checkbox'}
                                    readOnly={true}
                                    checked={stagedWorkTicket
                                        ?.is_project ?? false}
                                />
                            </Form.Check>
                        </TableCol>
                        <TableCol xs={3} lg={2}>
                            {stagedWorkTicket?.ship_date
                                ? DateTime
                                    .fromISO(stagedWorkTicket.ship_date)
                                    .toLocaleString()
                                : ''}
                        </TableCol>
                        <TableCol xs={5} className={'d-none d-lg-block'}>
                            {_.join(
                                stagedWorkTicket.group_work_ticket_numbers,
                                ' | ',
                            )}
                        </TableCol>
                        <TableColActions xs={1}>
                            <TableIcon
                                src={deleteIcon}
                                alt={'Delete'}
                                $background={'danger'}
                                onClick={() => setDeleteStagedWorkTicket(
                                    stagedWorkTicket,
                                )}
                            />
                        </TableColActions>
                    </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
        <DeleteStagedWorkTicketModal
            deleteStagedWorkTicket={deleteStagedWorkTicket}
            setDeleteStagedWorkTicket={setDeleteStagedWorkTicket}
            onDelete={onDelete}
        />
    </>;
};

export default StagedWorkTicketList;
