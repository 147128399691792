import React, {useState, useRef, useEffect} from 'react';
import _ from 'lodash';
import CustomAccordion from '^common/customAccordion';
import {Accordion, Form} from 'react-bootstrap';
import {FormSelect} from '^common/formSelect';
import AddToCard from '^pages/workTicket/commonAddtoAssemblyKitting/addToCard';
import {toggleAccordion}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';

const AddToAssemblyKittingFilter = ({
    type,
    hide,
    entityList,
    barcodeUnits,
    filters,
    setters,
    partConfigOptions,
    descendantTrees,
    handleSubmit,
}) => {
    const skuInputRef = useRef(null);

    const [options, setOptions] = useState([]);
    const [partConfigInput, setPartConfigInput] = useState('');

    const {
        serializedFilter = null,
        partConfigFilter = null,
        kitFilter = null,
    } = filters;

    const {
        setComponentsKey = null,
        setKitsKey = null,
        setSerializedFilter = null,
        setPartConfigFilter = null,
        setKitFilter = null,
    } = setters;

    useEffect(() => {
        if (partConfigOptions) {
            setTimeout(() => {
                skuInputRef?.current?.focus?.();
            }, 100);
        }
    }, [partConfigOptions]);

    useEffect(() => {
        if (partConfigFilter && entityList) {
            let filteredUnits;
            if (_.includes(partConfigFilter, '(Serialized)')) {
                filteredUnits = entityList[partConfigFilter];
            } else if (!_.isNil(barcodeUnits)) {
                filteredUnits = barcodeUnits[partConfigFilter];
            }
            setOptions(_.map(filteredUnits, (unit) => ({
                label: unit.serial_number,
                value: {...unit},
            })));
        }
    }, [entityList, partConfigFilter, barcodeUnits]);

    useEffect(() => {
        if (type === 'kit' && entityList) {
            setOptions(_.map(entityList, (kit) => ({
                label: kit.label,
                value: {...kit},
            })));
        }
    }, [entityList, type]);

    const filteredPartConfigOptions = _.filter(
        partConfigOptions,
        ({label}) => {
            const partInputRegExp = _.escapeRegExp(partConfigInput);
            const regex = new RegExp(`^${partInputRegExp}(?:$| .+)`);

            return partConfigInput
                ? regex.test(label)
                : false;
        },
    );

    return !hide && <CustomAccordion key={'Filter'}
        defaultActiveKey={'0'}>
        <Accordion.Item eventKey={'0'}>
            <Accordion.Header>{'Filter'}</Accordion.Header>
            <Accordion.Body>
                {type === 'assembly'
                    ? <Form.Group>
                        <Form.Text>{'SKU'}</Form.Text>
                        <FormSelect
                            ref={skuInputRef}
                            isClearable={true}
                            backspaceRemovesValue={true}
                            value={_.find(partConfigOptions,
                                {value: partConfigFilter})
                                ?? null}
                            options={filteredPartConfigOptions}
                            onInputChange={setPartConfigInput}
                            noOptionsMessage={({inputValue}) => inputValue
                                ? `SKU "${inputValue}" not found`
                                : 'Enter SKU'}
                            onChange={(e) => {
                                setPartConfigFilter(e?.value ?? null);
                                setSerializedFilter(null);
                            }}
                        />
                        {partConfigFilter
                            && <>
                                <Form.Text>{'Serial Number'}</Form.Text>
                                <FormSelect
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    value={_.find(options, (option) =>
                                        option?.value?.serial_number
                                            === serializedFilter?.serial_number)
                                        ?? null
                                    }
                                    options={options}
                                    onChange={(e) => {
                                        setSerializedFilter(e?.value ?? null);
                                        toggleAccordion(setComponentsKey, null);
                                    }}
                                />
                            </>
                        }</Form.Group>
                    : <Form.Group>
                        <Form.Text>{'Kit'}</Form.Text>
                        <FormSelect
                            isClearable={true}
                            backspaceRemovesValue={true}
                            value={_.find(options,
                                {value: kitFilter})
                                ?? null}
                            options={options}
                            onChange={(e) => {
                                setKitFilter(e?.value ?? null);
                                toggleAccordion(setKitsKey, null);
                            }}
                        />
                    </Form.Group>}
                {!_.isNil(serializedFilter)
                    && <AddToCard
                        assemble={true}
                        unit={serializedFilter}
                        descendantTrees={descendantTrees}
                        handleSubmit={handleSubmit}
                    />
                }
                {!_.isNil(kitFilter)
                    && <AddToCard
                        assemble={true}
                        kit={kitFilter}
                        handleSubmit={handleSubmit}
                    />
                }
            </Accordion.Body>
        </Accordion.Item>
    </CustomAccordion>;
};

export default AddToAssemblyKittingFilter;
